import { useQuery } from "@apollo/react-hooks"
import { gql } from "graphql-tag"

const GET_SERIES = gql`
  query GetSeriesV2($input: GetSeriesV2Input!) {
    getSeriesV2(input: $input) {
      id
      name
      image
      logo
    }
  }
`

export function useGetSeries(options = {}) {
  return useQuery(GET_SERIES, {
    variables: { input: options },
  })
}
