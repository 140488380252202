import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import TrackImage from "../trackHome/TrackImage"
import Loading from "../Loading"
import { Link } from "gatsby"
import { useGetSeries } from "../../gql/useGetSeries"
import {
  BodyWrapper,
  Row,
  GroupTitle,
  TrackImageContainer,
  TrackList,
} from "/src/styles/trackAndSeries.style"
import SearchHeader from "../SearchHeader"

export default function SeriesPage() {
  const [offset, setOffset] = useState(0)
  const [seriesList, setSeriesList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [queryString, setQueryString] = useState(null)
  const [search, setSearch] = useState(null)

  let alphabet = "A"

  const { loading, error, data } = useGetSeries({
    offset,
    ...(queryString ? { queryString } : null),
  })

  const series = !loading && data ? data.getSeriesV2 : []

  const observer = useRef()
  const lastTrackRef = useCallback(
    node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(series => {
        if (series[0].isIntersecting && hasMore) {
          setOffset(offset => offset + 10)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  useEffect(() => {
    if (offset === 0) setSeriesList(series)
    else setSeriesList(seriesList => seriesList.concat(series))
    setHasMore(series.length > 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  if (error) return `error: ${error.message}`

  const onSearch = () => {
    setQueryString(search)
    setOffset(0)
  }

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      setQueryString(search)
      setOffset(0)
    }
  }

  return !seriesList ? (
    <Loading />
  ) : (
    <>
      <BodyWrapper>
        <Row>
          <SearchHeader
            text="Series"
            setSearch={setSearch}
            handleKeyPress={handleKeyPress}
            search={search}
            onSearch={onSearch}
            searchText="Search Series"
          />

          {!error ? (
            <TrackList>
              <Row>
                {seriesList.map((series, index) => {
                  const showTitle =
                    series.name[0].toLowerCase() !== alphabet.toLowerCase() ||
                    index === 0
                  if (showTitle) alphabet = series.name[0]

                  //Attach the ref to the last element
                  if (index + 1 === seriesList.length) {
                    return (
                      <Fragment key={series.id}>
                        {showTitle ? (
                          <GroupTitle key={series.name[0].toUpperCase()}>
                            {series.name[0].toUpperCase()}
                          </GroupTitle>
                        ) : null}
                        <Link to={`/series/${series.id}`}>
                          <TrackImageContainer ref={lastTrackRef}>
                            <TrackImage
                              name={series.name}
                              logo={series.logo}
                              image={series.image}
                              location={`${series.city} ${series.state}`}
                              isSeries={true}
                            />
                          </TrackImageContainer>
                        </Link>
                      </Fragment>
                    )
                  } else {
                    return (
                      <>
                        {showTitle ? (
                          <GroupTitle key={series.name[0].toUpperCase()}>
                            {series.name[0].toUpperCase()}
                          </GroupTitle>
                        ) : null}
                        <Link to={`/series/${series.id}`} key={index}>
                          <TrackImageContainer key={series.id}>
                            <TrackImage
                              name={series.name}
                              logo={series.logo}
                              image={series.image}
                              location={`${series.city} ${series.state}`}
                              isSeries={true}
                            />
                          </TrackImageContainer>
                        </Link>
                      </>
                    )
                  }
                })}
                {loading ? <Loading /> : null}
              </Row>
            </TrackList>
          ) : (
            <p>Sorry, couldn't fetch Track List at the moment.</p>
          )}
        </Row>
      </BodyWrapper>
    </>
  )
}
