import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SmartBanner from "react-smartbanner"
import "../../node_modules/react-smartbanner/dist/main.css"
import SeriesPage from "../components/series/SeriesPage"

const Series = () => {
  return (
    <Layout
      maxHeight={true}
    >
      <SmartBanner title={"Ticket Hoss"} />
      <SEO title="Ticket Hoss" />

      <SeriesPage />
    </Layout>
  )
}

export default Series
